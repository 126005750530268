/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');


/*  */
/* common css */
/* .heading-wrapper h2 {
    font-family: "Cinzel", serif;
    color: var(--primary);
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 15px;
    font-weight: 400;
} */
.fd_headings {
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    font-family: "Cinzel", serif !important;
    text-transform: uppercase;
    color: #000000;
}

.fd_paras {
    font-weight: 400;
    font-size: 17px;
    line-height: 25.87px;
    letter-spacing: 2px;
    font-family: Futura;
    color: #000000;
}

button.firerock_button {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #000000;
    font-weight: 500;
    padding: 10px 22px;
}

.fd_spacve_one {
    padding-top: 100px;
}

.fd_white_box a {
    text-decoration: underline !important;
    color: black;
}

.fdcustum_container {
    max-width: 1600px;
}

/*  */

/* About Firerock Diamonds  */
.fd_about_firefocks p.fd_paras {
    width: 620px;
}

.fd_about_firefocks {
    margin-left: 50px;
}

/* ETernal Rings*/
.fd_white_box {
    text-align: center;
    padding: 34px 0;
    background-color: white;
    box-shadow: 0px 7px 16.600000381469727px 0px #0000000D;
    /* width: 790px; */
}


/* FLAMES of Bueaty */
.fd_flames_of_color {
    background-image: url('../images/firerock-daimonds-img/flames.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 804px;
    display: flex;
    align-items: center;
}

.fd_falms_colo p.fd_paras {
    font-size: 20px;
    line-height: 31.04px;
    width: 50%;
}

/* .fd_falms_colo {
    margin-top: 30%;
} */
.fd_falmes.mob_beig {
    background-color: #E8DDD1;
}

.fd_design_custom_engagement_rings {
    padding-bottom: 100px;
}

button.firerock_button.btn:hover,
button.firerock_button.btn:active,
button.firerock_button.btn:focus {
    color: #fff;
    background-color: #000;
    border-color: #0000;
    box-shadow: none;
    /* border: 1px solid rgba(0, 0, 0, 1) !important; */
    /* background: #112738; */
    /* color: rgba(0, 0, 0, 1); */
    /* border-radius: 6px; */
    /* font-weight: 600; */
}

/*\\\\\\\\\\\\\\\\\\\\\\\Shop Diamonds By Shape \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
.fd_shpe_heading {
    text-align: center;
}

.fd_shapes {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fd_diamd {
    text-align: center;
}

.fd_shop_diamonds_by_shape {
    background-color: #f0fbfc;
    padding-top: 80px;
    margin-top: 100px;
    padding-bottom: 80px;
}

.fd_diamd h5 {
    font-family: 'Futura' !important;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}





















/* responsive */
@media (min-width: 320px) and (max-width: 767px) {
    .fd_about_firefocks p.fd_paras {
        width: 100%;
    }

    .fd_about_firefocks {
        text-align: center;
    }

    .fd_about_firefocks {
        margin-left: 0;
    }

    .fd_headings {
        font-size: 29px;
        line-height: 40.92px;
    }

    .fd_paras {
        font-size: 14px;
        line-height: 21.87px;
        letter-spacing: 1px;
    }

    .fd_about_firefocks h2.fd_headings {
        padding-top: 20px;
    }

    .fd_spacve_one {
        padding-top: 50px;
    }

    .fd_etspace {
        padding-top: 20px;
    }

    .fd_white_box {
        padding: 12px 0;
    }

    .fd_falms_colo {
        margin-top: 0;
        padding-top: 26px;
        text-align: center;
        padding-bottom: 26px;
    }

    .fd_falms_colo p.fd_paras {
        font-size: 14px;
        line-height: 21.04px;
        letter-spacing: 0px;
        width: 85%;
        margin: auto;
    }

    .gs_news_form {
        width: 100% !important;
    }

    .gs_news_form button#button-addon2 {
        margin-top: 8px;
    }

    .fd_diamd img {
        width: auto;
        margin: 0 auto;
    }

    .fd_shop_diamonds_by_shape {
        background-color: #f0fbfc;
        padding-top: 26px;
        margin-top: 50px;
        padding-bottom: 36px;
    }

    .fd_flames_of_color_mobile .f.container-fluid {
        margin: 0;
        padding: 0;
    }

    .fd_shapes_mobile .slick-next {
        right: 11px;
    }

    .fd_shapes_mobile .slick-prev {
        left: 0;
    }

    .fd_design_custom_engagement_rings {
        padding-bottom: 50px;
    }

    .mob_beig button.firerock_button.btn.btn-outline-dark {
        margin-top: 20px;
        margin-bottom: 11px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .fd_shop_diamonds_by_shape {
            padding-top: 29px;
        padding-bottom: 40px;
    }
    .rcs_customer_say_section {
        padding: 30px 0;
    }
    .rcs_signup_to_section {
        padding: 50px 0 !important;
    }
    .section-space {
        padding: 21px 0 0;
    }
    .heading-wrapper {
        padding-bottom: 0;
    }
    .fd_diamd img {
        width: 85px !important;
        height: auto;
    }





    .fd_white_box {
        /* text-align: center; */
        padding: 10px 0;
    }

    .fd_spacve_one {
        padding-top: 50px;
    }

    .fd_about_firefocks p.fd_paras {
        width: 100%;
    }

    .fd_about_firefocks {
        text-align: center;
    }

    .fd_about_firefocks {
        margin-left: 0;
    }

    .fd_headings {
        font-size: 23px;
        line-height: 37.92px;
    }

    .fd_paras {
        font-size: 14px;
        line-height: 21.87px;
        letter-spacing: 1px;
    }

    .fd_about_firefocks h2.fd_headings {
        padding-top: 20px;
    }

    .fd_falms_colo p.fd_paras {
        font-size: 14px;
        line-height: 19.04px;
        letter-spacing: 1px;
    }

    .fd_flames_of_color {
        height: 330px;
    }

    /* .fd_falms_colo {
        margin-top: 15%;
    } */
    .fd_flames_of_color_mobile {
        display: none;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .fd_white_box {
        text-align: center;
        padding: 5px 0;
    }
    .fd_diamd img {
        width: 85px !important;
        height: auto;
    }
    .fd_shop_diamonds_by_shape {
        /* background-color: #f0fbfc; */
        padding-top: 24px;
        padding-bottom: 27px;
    }
    .heading-wrapper {
        padding-bottom: 24px;
    }
    .rcs_customer_say_section {
        padding: 30px 0;
    }
    .fd_about_firefocks p.fd_paras {
        width: 100%;
    }

    .fd_about_firefocks {
        text-align: center;
    }

    .fd_about_firefocks {
        margin-left: 0;
    }

    .fd_headings {
        font-size: 23px;
        margin-bottom: 0px;
        /* line-height: 27.92px; */
    }
    .fd_design_custom_engagement_rings {
        padding-bottom: 50px;
    }
    .fd_shop_diamonds_by_shape {
        margin-top: 50px;
    }
    .fd_paras {
        font-size: 14px;
        line-height: 21.87px;
        letter-spacing: 1px;
    }

    .fd_about_firefocks h2.fd_headings {
        padding-top: 20px;
    }

    .fd_spacve_one {
        padding-top: 50px;
    }

    .fd_falms_colo p.fd_paras {
        font-size: 14px;
        line-height: 19.04px;
        letter-spacing: 1px;
    }

    .fd_flames_of_color {
        height: 427px;

    }

    /* .fd_falms_colo {
        margin-top: 15%;
    } */
}

@media (min-width:1025px) and (max-width:1199px) {
    .fd_about_firefocks p.fd_paras {
        width: auto;
    }

    .fd_about_firefocks {
        margin-left: 0;
    }
}

@media (min-width:1200px) and (max-width:1440px) {
    .fd_diamd img {
        width: 75px;
        height: auto;
    }

    .fd_spacve_one {
        padding-top: 60px;
    }

    .fd_design_custom_engagement_rings {
        padding-bottom: 60px;
    }
}

/* 150% */
@media (min-width:1280px) and (max-width:1439px) {

    .fd_about_firefocks p.fd_paras {
        width: 100%;
    }

    .fd_headings {
        font-size: 33px;
        line-height: 31.92px;
    }

    .fd_paras {
        font-size: 15px;
        line-height: 21.87px;
    }

    .fd_flames_of_color {
        height: 533px;
    }

    /* .fd_falms_colo {
        margin-top:20%;
    } */
    .fd_falms_colo p.fd_paras {
        font-size: 16px;
        line-height: 24.04px;
        letter-spacing: 2px;
    }

    .fdcustum_container {
        max-width: 1200px;
    }

    .rcs_customer_review_slide_img img {
        width: 110px;
    }
}

/* 110% to 133%  */
@media (min-width:1440px) and (max-width:1900px) {
    .fdcustum_container {
        max-width: 1200px;
    }

    .fd_falms_colo p.fd_paras {
        font-size: 19px;
        line-height: 25.04px;
    }

    .fd_about_firefocks p.fd_paras {
        width: 100%;
    }

    .fd_headings {
        font-size: 33px;
        line-height: 31.92px;
    }

    .fd_paras {
        font-size: 15px;
        line-height: 21.87px;
    }

    .fd_flames_of_color {
        height: 646px;
    }

    .LandingPageShocase .showcase-container,
    .rcs_footer .showcase-container,
    .rcs_footer_bottom .showcase-container,
    .showcase-container {
        max-width: 96%;
    }
}

@media (min-width:1441px) and (max-width:1900px) {}