@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
    --main-color-black: rgba(0, 0, 0, 1);
    --main-background-color: rgba(240, 251, 252, 1);
  }
  @font-face {
    font-family: 'Futura';
    font-style: normal;
    font-weight: normal;
    src: url('../font/firerock-fonts/FuturaMedium.otf') format('opentype');
    src: url('../font/firerock-fonts/futur.ttf');
    src: url('../font/firerock-fonts/futuralightbt.ttf');
    /* src: url('../font/firerock-fonts/futuramediumbt.ttf'); */
    /* src: url('../font/firerock-fonts/FuturaLightfont.ttf'); */

}

  h1, h2, h3, h4, h5, h6{
      font-family: "Cinzel", serif;
  }
  body{
      font-family: 'Futura', sans-serif !important;
  }
  
  p{
    font-family: 'Futura' !important;
    font-weight: 400 !important;
  }

.frd_custome_container{
    width: 100%;
    max-width: 1600px;
}

/* collection style start */
  .frd_collection_outer_box ul li{
display: inline-block;
width: 20%;
  }
  .frd_collection_inner_box {
	width: 100%;
	height: 290px;
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: end;
	justify-content: center;
	padding-bottom: 20px;
	background-size: contain;
    margin-bottom: 30px;
}
.frd_collection_inner_box_before_inner {
	padding: 7.5px;
}
.frd_search_icon{
    opacity: 1;
}

/* collection style end */

/* customer style */

/* custumer style ends */
.rcs_customer_review_slide_img img {
	margin: 0 auto;
	width: 120px;
}
.rcs_customer_review_slide_title {
	margin: 0 auto;
	text-align: center;
}
.rcs_customer_say_section .rcs_customer_review_slide_item {
	border: 1px solid #ffffff;
	background-color: white;
	box-shadow: 0px 4px 10px 0px #0000001A;
	border-radius: 10px;
	min-height: 295px;
}
.rcs_customer_say_section {
    background-color: #F0FBFC;
}
.rcs_customer_slide_text_content{
    text-align: center;
}
.rcs_customer_say_section .rcs_customer_slide_text_content p {
    font-size: 16px;
    line-height: 1.5;
}
.rcs_customer_review_slider {
	padding: 0px 50px;
}

/* header  style start */
.megamenu_showcase_center_header_left__5bTdA svg {
	font-size: 20px;
}
.megamenu_showcase_center_header_left__5bTdA .frd_header_mail {
	font-size: 23px;
}
.gs_news_form{
	width: 55% !important;
	margin: 0 auto;
}

/* Responsive media */

@media(min-width:320px) and (max-width:767px){
    .frd_collection_inner_box {
        height: 170px;
        margin-bottom: 10px;
        padding-bottom: 0;
    }
    .frd_explore_Collection_Slider .slick-dots li button::before {
        width: 10px !important;
        content: ' ' !important;
        background: #000 !important;
        height: 2px !important;
    }
    .rcs_customer_say_section .rcs_customer_slide_text_content p {
        font-size: 14px;
    }
    .rcs_customer_review_slider {
        padding: 0;
    }
    .rcs_customer_review_slide_img img {
        width: 106px;
    }

}

@media(min-width:768px) and (max-width:991px){
    .fd_shop_diamonds_by_shape {
        margin-top: 50px !important;
    }
    .fd_design_custom_engagement_rings {
        padding-bottom: 60px !important;
    }
    .fd_headings {
        font-size: 20px;
        line-height: 30.92px;
    }
    .showcase-container {
        width: 95%;
        max-width: 100%;
        margin: 0 auto;
    }
    .frd_collection_inner_box {
        width: 92% !important;
        height: 200px !important;
        margin: 0 auto 15px !important;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .frd_collection_inner_box {
        width: 92% !important;
        height: 200px !important;
        margin: 0 auto 15px !important;
    }

    .showcase-container {
        width: 95%;
        max-width: 100%;
        margin: 0 auto;
    }
    .frd_collection_inner_box {
        width: 90%;
        height: 200px;
        padding-bottom: 20px;
    }
    .explore-collection .item a {
        font-size: 20px;
        line-height: 30px;
        padding-top: 15px;
    }
}
@media(min-width:1025px) and (max-width:1199px){
    .showcase-container {
        width: 90%;
        max-width: 100%;
        margin: 0 auto;
    }
}

@media(min-width:1200px) and (max-width:1440px){
    .rcs_customer_say_section .rcs_customer_slide_text_content p {
        font-size: 14px;
        line-height: 20px;
    }
    .LandingPageShocase .showcase-container, .rcs_footer .showcase-container, .rcs_footer_bottom .showcase-container, .showcase-container {
        width: 96%;
        padding: 0;
    }
    .frd_collection_inner_box {
        width: 95%;
        height: 230px;
    }
    .explore-collection .item a {
        font-size: 20px;
        line-height: 30px;
        padding-top: 15px;
    }
    .gs_news_form {
        width: 50% !important;
        margin: 0 auto;
    }
    .rcs_hero_slider{
    height: 460px;
    }
    .rcs_customer_say_section .rcs_customer_review_slide_item {
        min-height: 280px;
    }
}
